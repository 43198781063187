<template>
  <div>
    <GameRoomList :gameRooms="publicGameList" :isPrivate="false"/>
  </div>
</template>

<script>
import GameRoomList from '../../components/GameRoomList';

export default {
  components:{
    GameRoomList
  },
  data(){
    return{
      publicGameList: []
    }
  },
  computed:{
    gameList(){
      if(!this.userRole === 'admin' || !this.userRole == 'trainer'){
        return this.$store.getters.getGameList.filter(item => item.type === "PUBLIC" && item.is_active == 1);
      }
      return this.$store.getters.getGameList.filter(item => item.type === "PUBLIC");
    }
  },
  watch:{
    gameList(newValue){
      this.publicGameList = newValue
    }
  },
  mounted(){
    this.publicGameList = this.gameList;
  }
}
</script>

<style>

</style>